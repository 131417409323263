import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts/Layout'
import LayoutArchive from '../layouts/LayoutArchive'


//Component
const InspoTemplate = ( props ) => {

  const data = props.data

  const current_meta = {
    meta_title:     data.wordpressAcfOptions.options.inspo_meta_title,
    meta_desc:      data.wordpressAcfOptions.options.inspo_meta_desc,
  }

  const category_items = data.allWordpressWpInspoCategory.edges
  const archive_items = data.allWordpressWpInspo.edges

  return (
    <div>
      <Layout 
        current_meta = {current_meta} 
      >

        <LayoutArchive 
          category_items = {category_items}
          archive_items = {archive_items}
          title_only_item = {true}
        />

      </Layout>
    </div>
  )
}


//Export
export default InspoTemplate


//Query
export const articlesQuery = graphql`
query articlesQuery{
  wordpressAcfOptions {
    options {
      inspo_meta_title
      inspo_meta_desc
    }
  }
  allWordpressWpInspoCategory (sort: {fields: description, order: ASC }) {
    edges {
      node {
        name
        slug
        link
      }
    }
  }
  allWordpressWpInspo (sort: {fields: menu_order, order: ASC}) {
    edges {
      node {
        id
        title
        link
        acf {
          heading
          main_image {
            alt_text
            localFile {
              childImageSharp {
                fixed(width: 600, height: 400, cropFocus: CENTER) {
                  ...Fixed
                }
              }
            }
          }
        }
        cpt_categories {
          name
          slug
        }
      }
    }
  }
}
`